<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">帮助文档</div>
        <el-button type="primary" @click="handleEdit()" v-permission="['新增文档']">新增文档</el-button>
      </div>
    </div>

    <!-- 列表 -->
    <el-table ref="table" :data="tableData.items" v-loading="loading">
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="create_time" label="创建时间"></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template #default="scope">
          <el-button plain size="small" type="primary" v-permission="['编辑文档']" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button plain size="small" type="danger" v-permission="['删除文档']" @click="handleDelete(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>

    <update-info ref="updateInfo" @update="getData"></update-info>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import commonHandle from '@/hooks/useCommon'
import infoModel from '@/model/information'
import updateInfo from './components/update-info'

export default {
  components: {
    updateInfo,
  },
  setup(props) {
    const updateInfo = ref(null)

    const getData = async pageQuery => {
      try {
        loading.value = true
        const res = await infoModel.getInfoList({
          page: pageQuery?.page || pageParam.currentPage - 1,
          count: pageQuery?.count || pageParam.count,
        })
        tableData.value = res
        return res
      } finally {
        loading.value = false
      }
    }

    // 删除
    const deleteFun = async id => {
      const res = await infoModel.deleteInfo(id)
      return res
    }

    // 新增/编辑教程
    const handleEdit = (row = {}) => {
      updateInfo.value.init(row)
    }

    const { tableData, loading, pageParam, handleDelete, pageEmit } = commonHandle(getData, deleteFun)

    onMounted(() => {
      getData()
    })

    return {
      tableData,
      updateInfo,
      loading,
      pageParam,
      pageEmit,
      handleEdit,
      handleDelete,
      getData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
